/* eslint-disable react/function-component-definition */
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import VisaCard from 'assets/visa.png';
import MasterCard from 'assets/mastercard.png';
import Delete from 'assets/trash.png';
import Invoice from 'assets/invoice.png';
import { Flex, Image } from 'styles';
import styled from 'styled-components';
import { IsMobile } from 'hooks/isMobile';
import { endpoints } from 'services/api/endpoints';
import { GetPaymentMethods, PaymentMethod } from 'services/api/paymentsApi';
import PaymentScreenDemo from 'views/PaymentScreen/PaymentScreen.demo';

export interface Transaction {
  id: string;
  transaction_time: number;
  currency: string;
  amount: number;
  status: string;
  renew_count: number;
  sku_name: string;
}

export function getDateTime(timestamp: number, withTime: boolean = false) {
  const optionWithTime: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  const optionWithDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  };

  const option = withTime ? optionWithTime : optionWithDate;
  return Intl.DateTimeFormat('en-US', option).format(timestamp);
}

const LoadMore = styled.div`
  color: #494141;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const CardHeading = styled.div`
  color: #494141;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const HeadingIcon = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

const CardMaskedDetail = styled.div`
  color: #484c4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const CardExpiry = styled.div`
  color: #9ca8b0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
  margin-left: 5px;
`;

const ExpiryContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const AvailablePaymentMethods = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin-top: 32px;
`;

export const Billings = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [paymentOption, setPaymentOption] = useState<PaymentMethod[]>([]);
  const [page, setPage] = useState(1);
  const [loadErr, setLoadErr] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [paymentOptionSelected, setPaymentOptionSelected] =
    useState<string>('');

  const handleChange = (e: { target: { value: string } }) => {
    setPaymentOptionSelected(e.target.value);
  };

  const fetchSavedCards = () => {
    GetPaymentMethods()
      .then((res) => {
        setPaymentOption(res.data.payment_method || []);
      })
      .catch((err) => console.log('err payment_methods', err))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const addCardDetails = () => {
    fetchSavedCards();
    setPaymentOptionSelected('');
  };

  useEffect(() => {
    setIsLoading(true);
    fetchSavedCards();
  }, []);

  const LoadMoreDetails = () => {
    axios
      .get(endpoints.getTransactionsUrl(page))
      .then((res) => {
        if (res.data !== null) {
          setTransactionList((state) => state.concat(res.data));
        } else {
          setLoadErr('No more paymebts to load');
        }
      })
      .catch((err) => {
        console.log('purchase', err);
        setLoadErr('No more paymebts to load');
      });
  };

  const downloadInvoice = (transaction_id: string) => {
    axios
      .get(`/piggybank/purchases/transactions/${transaction_id}/invoice`)
      .then((res) => {
        console.log('download success', res.data);
        // window.open(res?.data?.invoice_link, '_blank');
        window.location.href = res?.data?.invoice_link;
      })
      .catch((err) => {
        console.log('download error', err);
      });
  };

  const deleteCard = (cardId: string) => {
    const url = `${endpoints.paymentMethodsUrl}/${cardId}`;
    setIsLoading(true);
    axios
      .delete(url)
      .then((res) => {
        console.log('delete success', res);
        fetchSavedCards();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('delete err', err);
      });
  };

  useEffect(() => {
    LoadMoreDetails();
  }, [page]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card
      sx={{
        minWidth: 275,
        marginTop: '10px',
        padding: '0px',
        boxShadow: IsMobile() ? 'none' : 'inherit'
      }}
    >
      <CardContent
        sx={{
          padding: IsMobile() ? '0px' : '16px'
        }}
      >
        <Flex>
          <HeadingIcon />
          <CardHeading>Billing and Invoices</CardHeading>
        </Flex>
        <AvailablePaymentMethods>
          Available Payment Methods
        </AvailablePaymentMethods>
        <Card
          sx={{
            boxShadow: 'none',
            backgroundColor: IsMobile() ? '#F0F4F4' : '#FFF',
            padding: IsMobile() ? '8px' : '0px',
            borderRadius: '20px',
            margin: '16px 0'
          }}
        >
          <Card
            sx={{
              boxShadow: 'none',
              padding: IsMobile() ? '12px' : '0px',
              borderRadius: '12px',
              paddingBottom:
                paymentOptionSelected === 'ADDCARD' ? '20px' : '0px'
            }}
          >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChange}
              value={paymentOptionSelected}
            >
              {paymentOption.map(
                (el: {
                  id: string;
                  card: {
                    brand: string;
                    last4: string;
                    exp_year: number;
                    exp_month: number;
                  };
                }) => {
                  const {
                    id,
                    card: { last4, brand, exp_month, exp_year }
                  } = el;
                  return (
                    <Flex
                      key={id}
                      justify={IsMobile() ? 'space-between' : 'start'}
                    >
                      <FormControlLabel
                        key={id}
                        value={id}
                        disabled
                        control={<Radio />}
                        label={
                          <Flex align="center" mleft="5" mright="10">
                            <Image
                              src={brand === 'visa' ? VisaCard : MasterCard}
                              width={brand === 'visa' ? '42' : '32'}
                              height={brand === 'visa' ? '13' : '24'}
                            />
                            <Flex direction="column" mleft="10">
                              <CardMaskedDetail>
                                **** **** **** {last4}
                              </CardMaskedDetail>
                              <ExpiryContainer>
                                <CardExpiry>Expiry</CardExpiry>
                                <CardExpiry>
                                  {exp_month < 10 && '0'}
                                  {exp_month} / {exp_year}
                                </CardExpiry>
                              </ExpiryContainer>
                            </Flex>
                          </Flex>
                        }
                      />
                      {paymentOption.length > 1 && (
                        <Flex
                          bgcolor="#E5EBEC"
                          radius="8"
                          p="12"
                          mright="10"
                          onClick={() => deleteCard(el.id)}
                        >
                          <Image src={Delete} width="24" height="24" />
                        </Flex>
                      )}
                    </Flex>
                  );
                }
              )}
            </RadioGroup>
            {paymentOptionSelected !== 'ADDCARD' && (
              <Button
                variant="contained"
                onClick={() => setPaymentOptionSelected('ADDCARD')}
                sx={{
                  marginBottom: '20px',
                  background: 'primary.main'
                }}
              >
                Add Card
              </Button>
            )}

            {paymentOptionSelected === 'ADDCARD' && (
              <PaymentScreenDemo
                isUpgrade={false}
                isFreeTrial={false}
                amount={-1}
                submitPurchaseRequest={() => {
                  addCardDetails();
                }}
              />
            )}
          </Card>
        </Card>

        <Flex mtop="32" mbottom="24">
          <HeadingIcon />
          <CardHeading> Transaction History</CardHeading>
        </Flex>
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              border: '2px solid #F0F4F4',
              borderRadius: '8px'
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ border: 0 }}>
              <TableRow>
                <TableCell>Transaction</TableCell>
                <TableCell align="justify">Status</TableCell>
                <TableCell align="justify">Type</TableCell>
                <TableCell align="justify">Amount</TableCell>
                <TableCell align="justify">Transaction Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionList.map((transaction: Transaction) => (
                <TableRow
                  key={transaction.sku_name}
                  sx={{
                    border: 0,
                    '&:nth-child(odd)': { background: '#F0F4F4' }
                  }}
                >
                  <TableCell sx={{ border: 0 }}>
                    {transaction.sku_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0
                    }}
                    align="justify"
                  >
                    <Flex
                      bgcolor={
                        transaction.status === 'Success' ? '#D7EEA1' : '#FE832D'
                      }
                      height="28px"
                      justify="center"
                      style={{
                        borderRadius: '8px',
                        fontWeight: '500',
                        color:
                          transaction.status === 'Success' ? '#000' : '#FFF'
                      }}
                    >
                      {transaction.status}
                    </Flex>
                  </TableCell>
                  <TableCell
                    align="justify"
                    sx={{ border: 0 }}
                    onClick={() => downloadInvoice(transaction.id)}
                  >
                    <Flex
                      bgcolor="#F8F38B"
                      height="28px"
                      justify="center"
                      style={{
                        borderRadius: '8px',
                        fontWeight: '500',
                        color: '#000',
                        cursor: 'pointer'
                      }}
                    >
                      <Image
                        src={Invoice}
                        height="16"
                        width="16"
                        style={{ marginRight: 10 }}
                      />
                      {transaction.status === 'Success' ? 'Invoice' : ''}
                    </Flex>
                  </TableCell>
                  <TableCell align="justify" sx={{ border: 0 }}>
                    ${transaction.amount / 100}
                  </TableCell>
                  <TableCell align="justify" sx={{ border: 0 }}>
                    {dayjs(transaction.transaction_time).format(
                      'YYYY.MM.DD HH:mm:ss'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loadErr === '' ? (
          <Flex
            justify="center"
            mtop="10"
            onClick={() => setPage((state) => state + 1)}
          >
            <LoadMore>Load More Payments</LoadMore>
            <ExpandMoreIcon />
          </Flex>
        ) : (
          <Flex mtop="10" justify="center">
            <LoadMore>{loadErr}</LoadMore>
          </Flex>
        )}
      </CardContent>
    </Card>
  );
};
