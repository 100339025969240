/* eslint-disable react/function-component-definition */
import InputText from 'components/InputField/InputText';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { IsMobile } from 'hooks/isMobile';

const Card = styled.div`
  padding: ${IsMobile() ? '0px' : '24px'};
  background-color: ${IsMobile() ? '#fff' : '#fcfcfc'};
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: ${IsMobile()
    ? 'none'
    : `
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    `};
`;

const CardHeading = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const HeadingIcon = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const UserInfo = () => {
  const userDetails = useAppSelector((state) => state.user.userDetails);
  return (
    <Card>
      <FlexContainer>
        <HeadingIcon />
        <CardHeading> User Management</CardHeading>
      </FlexContainer>

      <InputText
        label="First Name"
        value={userDetails?.first_name || ''}
        type="text"
        isDisabled
      />
      <InputText
        label="Last Name"
        value={userDetails?.last_name || ''}
        type="text"
        isDisabled
      />
      <InputText
        label="Phone Number"
        value={`${userDetails?.phone_number} ` || ''}
        type="text"
        isDisabled
      />
      <InputText
        label="Email"
        value={userDetails?.email || ''}
        type="text"
        isDisabled
      />
    </Card>
  );
};
