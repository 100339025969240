/* eslint-disable import/no-extraneous-dependencies */
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import envConfig from './envConfig';

const stripeKey = envConfig('stripeKey') || '';
if (!stripeKey) {
  console.log('Stripe is not configured');
}

const stripePromise = loadStripe(stripeKey);

const stripeOptions: StripeElementsOptions = {
  clientSecret: '',
  appearance: {
    theme: 'stripe',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: 'black',
      colorPrimary: '#EFC078',
      colorPrimaryText: '#1A1B25',
      colorText: 'black',
      colorTextSecondary: 'black',
      colorTextPlaceholder: '#727F96',
      colorIconTab: 'black',
      colorLogo: 'dark'
    },
    rules: {
      '.Input, .Block': {
        backgroundColor: 'transparent',
        border: '1.5px solid #F0F4F4',
        borderRadius: '25px'
      }
    }
  }
};

export { stripeOptions, stripePromise };
