import { createTheme } from '@mui/material/styles';
import { IsMobile } from 'hooks/isMobile';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5FA4FB',
      contrastText: '#fff'
    },
    secondary: {
      main: '#E5EBEC',
      contrastText: '#484C4F'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: IsMobile() ? '100%' : 'auto',
          height: '48px',
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: '8px'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 'none',
          boxShadow: 'none'
        }
      }
    }
  }
});
export const CUSTOM_THEME = {
  primaryColor: '#5FA4FB',
  primaryBackground: '#D7EEA1',
  grayBackground: '#F0F4F4'
};
export default theme;
