/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const redirectionURL = '/setup-init';

function AuthorizeDevice() {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const deviceId = query.get('device_id') || '';

  useEffect(() => {
    navigate(`${redirectionURL}?device_id=${deviceId}&pairingFlow=true`);
  }, []);

  return null;
}
export default AuthorizeDevice;
