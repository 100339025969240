/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as PlanIcon } from 'assets/queue-list.svg';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ReactComponent as BillingIcon } from 'assets/bill.svg';
import { ReactComponent as SubscriptionIcon } from 'assets/subscription.svg';
import { ReactComponent as DeviceIcon } from 'assets/device.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { IsMobile } from 'hooks/isMobile';
import styled from 'styled-components';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { CheckFreeTrialSubscription } from 'services/api/subscriptionApi';
import { updateFreeTrialEligibility } from 'store/reducers/userReducers';
import { ConfigContext } from 'context';

const drawerWidth = IsMobile() ? window.screen.availWidth : 340;

type NavData = {
  name: string;
  icon: ReactElement;
  link: string;
};
export const leftNavData: NavData[] = [
  {
    name: 'Devices',
    icon: <DeviceIcon />,
    link: '/ActiveDevices'
  },
  {
    name: 'User',
    icon: <ManageAccountsIcon />,
    link: '/MyAccount'
  },
  {
    name: 'Subscription',
    icon: <SubscriptionIcon />,
    link: '/MySubscription'
  },
  {
    name: 'Billing and Invoice',
    icon: <BillingIcon />,
    link: '/Billings'
  },
  {
    name: 'Our Plans',
    icon: <PlanIcon />,
    link: '/planlist'
  }
];

type IProps = {
  setOpenSideBar: (state: boolean) => void;
  openSideBar: boolean;
};

function SideBar(props: IProps) {
  const { setOpenSideBar, openSideBar } = props;
  const isMobile = IsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const index = leftNavData.findIndex((el) => el.link === location.pathname);
  const [selectedNav, setSelectedNav] = useState<NavData>(leftNavData[index]);
  const config = useContext(ConfigContext);
  const SidebarLogo = config.logoIcon;

  useEffect(() => {
    setSelectedNav(leftNavData[index]);
  }, [index]);

  useEffect(() => {
    dispatch(fetchAllSubscription());
    CheckFreeTrialSubscription()
      .then((res) => {
        dispatch(updateFreeTrialEligibility(res.data.trial));
      })
      .catch((err) => {
        console.log('free trial check failed', err);
      });
  }, []);

  const onSideNavClick = (nav: NavData) => {
    setSelectedNav(nav);
    navigate(nav?.link);
    if (isMobile) {
      setOpenSideBar(false);
    }
  };

  const logoClick = () => {
    navigate('/ActiveDevices');
    setOpenSideBar(false);
  };

  const list = () => {
    return (
      <List>
        <SidebarLogo onClick={logoClick} width={60} height={60} />
        {leftNavData.map((nav) => (
          <ListItem key={nav?.name} disablePadding>
            <ListItemButton
              selected={selectedNav?.name === nav.name}
              style={{
                borderRadius: '12px',
                alignItems: 'center',
                backgroundColor:
                  selectedNav?.name === nav.name
                    ? 'rgba(95, 164, 251, 0.15)'
                    : 'transparent',
                color: selectedNav?.name === nav.name ? '#5FA4FB' : '#FFF',
                fontSize: '15px',
                fontWeight: '500',
                lineHeight: '24px'
              }}
              onClick={() => onSideNavClick(nav)}
            >
              <ListItemIcon
                sx={{
                  color: selectedNav?.name === nav.name ? '#5FA4FB' : '#FFF',
                  padding: 0,
                  minWidth: 0,
                  marginRight: '12px',
                  width: '24px',
                  height: '24px'
                }}
              >
                {nav.icon}
              </ListItemIcon>
              <ListItemText primary={nav.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };
  return (
    <Drawer
      sx={{
        padding: '24px',
        backgroundColor: 'black',
        width: drawerWidth,
        flexShrink: 0,
        top: '60px',
        '& .MuiDrawer-paper': {
          backgroundColor: 'black',
          width: drawerWidth,
          padding: '24px',
          top: isMobile ? 60 : 0
        }
      }}
      variant={isMobile ? 'temporary' : 'permanent'}
      anchor="left"
      open={openSideBar}
    >
      <SideBarNav>{list()}</SideBarNav>
    </Drawer>
  );
}
export default SideBar;

const SideBarNav = styled.div`
  width: 100%;
`;

// const LogoImage = styled.img`
//   width: 48px;
//   height: 48px;
//   margin-bottom: 48px;
// `;
