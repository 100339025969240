import { Button } from '@mui/material';
import { CUSTOM_THEME } from 'configs/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Camera from 'assets/camera_hd.png';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { IDeviceRes } from 'components/AddOrRemoveCamera';
import { endpoints } from 'services/api/endpoints';

interface CameraBlockProps {
  isSelected: boolean;
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 188px);
  overflow-y: auto;
`;
const TopBanner = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  text-align: center;
  padding: 0 0 10px 0;
`;
const CameraContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
`;
const CameraBlock = styled.div<CameraBlockProps>`
  background-color: ${(props) =>
    props.isSelected ? CUSTOM_THEME.primaryColor : CUSTOM_THEME.grayBackground};
  padding: 8px 20px 0px 20px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: ${(props) => (props.isSelected ? '#fff' : '')};

  & > * {
    color: ${(props) => (props.isSelected ? '#fff' : '')};
  }
`;
const LeftInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  width: auto;
  gap: 10px;
`;
const RightImageContainer = styled.div`
  padding: 0px 10px;
`;
const Image = styled.img``;
const HeadingText = styled.div<CameraBlockProps>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${(props) => (props.isSelected ? '#fff' : CUSTOM_THEME.primaryColor)};
`;
const ModelText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;
const SubText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;
const BottomContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
function CameraSelection() {
  const navigate = useNavigate();
  const [selectedCameras, setSelectedCameras] = useState<string[]>([]);
  const [cameras, setCameras] = useState<IDeviceRes[]>([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const spaceId = query.get('space_id') || '';
  const subscriptionId = query.get('subscription_id') || '';
  const cameraCount = 1;
  const fetchAllCameras = () => {
    try {
      axios.get(endpoints.getAllDeviceBySpaceIdUrl(spaceId)).then((res) => {
        setCameras(res.data.devices);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const toggleCameraSelection = (cameraId: string) => {
    if (selectedCameras.includes(cameraId)) {
      setSelectedCameras(selectedCameras.filter((id) => id !== cameraId));
    } else if (selectedCameras.length < cameraCount) {
      setSelectedCameras([...selectedCameras, cameraId]);
    }
  };
  const addCameraToSubscription = () => {
    const payload = {
      device_id: selectedCameras[0]
    };

    axios
      .post(`atlas/subscriptions/${subscriptionId}/devices`, payload)
      .then(() => {
        navigate('/subscription-summary');
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    fetchAllCameras();
  }, []);
  return (
    <Container>
      <TopContainer>
        <TopBanner>Which devices should be covered by Basic Plan?</TopBanner>
        <CameraContainer>
          {cameras.map((item) => (
            <CameraBlock
              key={item.id}
              isSelected={selectedCameras.includes(item.id)}
              onClick={() => toggleCameraSelection(item.id)}
            >
              <LeftInfoContainer>
                <HeadingText isSelected={selectedCameras.includes(item.id)}>
                  {item.name}
                </HeadingText>
                <ModelText>{item.manufacturer.model}</ModelText>
                <SubText>Not covered</SubText>
              </LeftInfoContainer>
              <RightImageContainer>
                <Image src={Camera} />
              </RightImageContainer>
            </CameraBlock>
          ))}
        </CameraContainer>
      </TopContainer>
      <BottomContainer>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '90%'
          }}
          onClick={addCameraToSubscription}
        >
          Continue
        </Button>
      </BottomContainer>
    </Container>
  );
}
export default CameraSelection;
