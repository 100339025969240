import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import userReducers, { clearUser } from './reducers/userReducers';
import subscriptionReducer, {
  clearSubscriptionData
} from './reducers/subscriptionReducer';

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  user: userReducers,
  subscription: subscriptionReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
  // .concat(logger)
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const clearReduxStates = () => {
  return store.dispatch(clearSubscriptionData());
};

export const LogoutUser = () => {
  store.dispatch(clearUser());
  clearReduxStates();
};
