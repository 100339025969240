import * as Sentry from '@sentry/react';
import envConfig from 'configs/envConfig';

if (envConfig('env') === 'prod') {
  Sentry.init({
    dsn: 'https://436c5f40160774f1341327cf5f14c9bb@o4507616560414720.ingest.us.sentry.io/4507616981680128',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    environment: envConfig('env'),
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      console.log(event, hint);
      return event;
    }
  });
}
