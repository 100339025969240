const _envConfig: { [key: string]: string | undefined } = {
  env: process.env['REACT_APP_ENVIRONMENT'],
  baseUrl: process.env['REACT_APP_BASE_URL'],
  firebaseApiKey: process.env['REACT_APP_FIREBASE_API_KEY'],
  firebaseAuthDomain: process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
  firebaseProjectId: process.env['REACT_APP_FIREBASE_PROJECT_ID'],
  firebaseStorageBucket: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET'],
  firebaseSenderId: process.env['REACT_APP_FIREBASE_SENDER_ID'],
  firebaseAppId: process.env['REACT_APP_FIREBASE_APP_ID'],
  firebaseMeasureId: process.env['REACT_APP_FIREBASE_MEASURE_ID'],
  stripeKey: process.env['REACT_APP_STRIPE_KEY'],
  domainUrl: process.env['REACT_APP_UI_DOMAIN_LINK']
};

const envConfig = (key: string) => {
  return _envConfig[key];
};

export default envConfig;
