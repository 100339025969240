/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactComponent as InstavisionLoginIcon } from 'assets/login_logo.svg';
import { ReactComponent as InstavisionLogoIcon } from 'assets/logo_icon.svg';

import { ReactComponent as LunaLoginIcon } from 'assets/luna/LoginLogo.svg';
import { ReactComponent as LunaLogoIcon } from 'assets/luna/LogoIcon.svg';

import { PARTNER_NAME, PARTNER_SHORT_NAME } from './config';

export interface TPartnerConfigurationDetails {
  singinLabel?: string;
  welcomeText?: string;
  loginLogoIcon?: any;
  logoIcon?: any;
  companyName?: string;
  email?: string;
  phone?: string;
  universalLink?: string;
}

export interface TPartnerConfiguration {
  [PARTNER_NAME: string]: TPartnerConfigurationDetails;
}

export const partnerConfiguration: TPartnerConfiguration = {
  [PARTNER_NAME.DEFAULT]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Instavision',
    loginLogoIcon: InstavisionLoginIcon,
    logoIcon: InstavisionLogoIcon,
    companyName: 'InstaVision',
    email: 'support@instababy.ai',
    phone: '+1 (888) 815-5886',
    universalLink: 'launchinstababyapp://instababy'
  },
  [PARTNER_NAME.LOCAL]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Instavision',
    loginLogoIcon: InstavisionLoginIcon,
    logoIcon: InstavisionLogoIcon,
    companyName: 'InstaBaby',
    email: 'support@instababy.ai',
    phone: '+1 (888) 815-5886',
    universalLink: 'launchinstababyapp://instababy'
  },
  [PARTNER_NAME.LUNA_DEV]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Luna AI',
    loginLogoIcon: LunaLoginIcon,
    logoIcon: LunaLogoIcon,
    companyName: 'LunaBaby',
    email: 'support@lunahomeai.com',
    phone: '+1 (855) 562-7480',
    universalLink: 'launchlunababyapp://lunababy'
  },
  [PARTNER_NAME.LUNA_STAGE]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Luna AI',
    loginLogoIcon: LunaLoginIcon,
    logoIcon: LunaLogoIcon,
    companyName: 'LunaBaby',
    email: 'support@lunahomeai.com',
    phone: '+1 (855) 562-7480',
    universalLink: 'launchlunababyapp://lunababy'
  },
  [PARTNER_NAME.LUNA_PROD]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Luna AI',
    loginLogoIcon: LunaLoginIcon,
    logoIcon: LunaLogoIcon,
    companyName: 'LunaBaby',
    email: 'support@lunahomeai.com',
    phone: '+1 (855) 562-7480',
    universalLink: 'launchlunababyapp://lunababy'
  }
};

type PartnerId = keyof typeof partnerConfiguration;

export const getPartnerConfiguration = (
  partnerId: PartnerId
): TPartnerConfigurationDetails => {
  if (partnerConfiguration[partnerId]) {
    return partnerConfiguration[partnerId];
  }
  return partnerConfiguration[PARTNER_NAME.DEFAULT];
};

export const getPartnerName = (partnerId: PartnerId) => {
  return PARTNER_SHORT_NAME[partnerId];
};
