import axios from 'axios';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export interface Plans {
  id: string;
  name: string;
  description: string[];
  supported_features: SupportedFeature[];
  type: string;
  trial_period: number;
  subscription_cycle: number;
  data_retention_days: number;
  supported_camera_count: number;
  level: number;
  prices: Price[];
}

export interface SupportedFeature {
  id: string;
  description: string;
}

export interface Price {
  country: string;
  currency: string;
  original_price: number;
  current_price: number;
  stripe_price_id: string;
}

export async function GetPlanDetails(): Promise<ApiResponse<Plans[]>> {
  const response = await axios.get(endpoints.plansUrl);
  return response;
}
