/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-redeclare */
import styled from 'styled-components';
import { HTMLInputTypeAttribute } from 'react';

function InputText(props: {
  label?: string;
  value: string | number;
  type: HTMLInputTypeAttribute;
  isDisabled?: boolean;
}) {
  const { label, value, type, isDisabled } = props;
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <InputTextField type={type} value={value} disabled={isDisabled} />
    </>
  );
}

export default InputText;

const InputLabel = styled.div`
  color: #484c4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin-bottom: 12px;
`;

const InputTextField = styled.input`
  border-radius: 12px;
  background: #f0f4f4;
  width: 100%;
  height: 48px;
  border: none;
  padding: 12px;

  color: #494141;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin-bottom: 17px;
`;
