/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from 'styles';
import { setRequestHeaders } from 'utils/commonFunctions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUser } from 'store/reducers/userReducers';
import CircularProgress from '@mui/material/CircularProgress';
import { clearReduxStates } from 'store/store';
import axios from 'axios';

const ContentContainer = styled.div`
  font-size: 30px;
  margin: 20px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 10px;
`;
const redirectionURL = '/setup-init';
type StatusMessages = {
  [key: string]: string;
};
const statusMessage: StatusMessages = {
  authorizing: 'Authorizing Request...',
  token_expired: 'Link is expired or invalid. Please generate link again.'
};

function AuthorizeDevice() {
  const [status, setStatus] = useState<string>('authorizing');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const [customTokenVerified, setCustomTokenVerified] = useState(false);

  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';
  const deviceId = query.get('device_id') || '';

  const verifyToken = (one_time_token: string) => {
    axios
      .post(`/oauth2/one-time-token/login?token=${one_time_token}`)
      .then((res) => {
        const { access_token } = res.data.data.token;
        setRequestHeaders(access_token);
        dispatch(fetchUser());
        setCustomTokenVerified(true);
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('fetchAccessToken', err);
      });
  };

  useEffect(() => {
    if (token === '') {
      setStatus('token_expired');
      return;
    }
    clearReduxStates();
    verifyToken(token);
  }, [token]);

  useEffect(() => {
    if (isAuthenticated && customTokenVerified) {
      navigate(`${redirectionURL}?device_id=${deviceId}&pairingFlow=true`);
    }
  }, [isAuthenticated, customTokenVerified]);

  return (
    <Flex justify="center" align="center" height="100vh">
      <ContentContainer>
        {status === 'authorizing' && <CircularProgress size={30} />}
        {statusMessage[status]}
      </ContentContainer>
    </Flex>
  );
}
export default AuthorizeDevice;
