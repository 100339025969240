import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CameraSnapshot from 'assets/camera-view.png';
import { IsMobile } from 'hooks/isMobile';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: calc(100vh - 250px);
`;
const TopBanner = styled.div`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.22px;
  margin-bottom: 10px;
`;
const SubHeading = styled.div`
  color: #8c8a94;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  width: 80%;
  margin-bottom: 20px;
`;
const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: center;
  position: fixed;
  bottom: 0px;
  background-color: #ffffff;
  padding: 10px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
const InfoBlock = styled.div`
  border-radius: 23px;
  background-color: #f4f5f5;
  padding: 16px;
`;
const InfoHeading = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`;
const InfoContent = styled.div`
  color: #8c8a94;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

function SubscriptionFunctions() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location -- ', location);

  const deviceId = location?.state?.deviceId || null;

  return (
    <Wrapper>
      <TopBlock>
        <TopBanner>Are you sure?</TopBanner>
        <SubHeading>
          Without Instaview subscription you will be missing out on these
          things:
        </SubHeading>
        <MiddleContainer>
          <InfoBlock>
            <InfoHeading>Breathing Monitoring:</InfoHeading>
            <InfoContent>
              Ensure your baby&apos;s safety with real-time breathing
              monitoring.
            </InfoContent>
            <Image
              src={CameraSnapshot}
              borderRadius="15"
              width={IsMobile() ? `${window.innerWidth - 40}` : '335'}
            />
          </InfoBlock>
          <InfoBlock>
            <InfoHeading>Sleep Tracking:</InfoHeading>
            <InfoContent>
              Understand your baby&apos;s sleep patterns for better routines
            </InfoContent>
            <Image
              src={CameraSnapshot}
              borderRadius="15"
              width={IsMobile() ? `${window.innerWidth - 40}` : '335'}
            />
          </InfoBlock>
          <InfoBlock>
            <InfoHeading>Cry Detection:</InfoHeading>
            <InfoContent>
              Get alerted instantly when your baby needs you
            </InfoContent>
            <Image
              src={CameraSnapshot}
              borderRadius="15"
              width={IsMobile() ? `${window.innerWidth - 40}` : '335'}
            />
          </InfoBlock>
          <InfoBlock>
            <InfoHeading>Ad-Free Experience: </InfoHeading>
            <InfoContent>
              Enjoy uninterrupted monitoring without distractions
            </InfoContent>
            <Image
              src={CameraSnapshot}
              borderRadius="15"
              width={IsMobile() ? `${window.innerWidth - 40}` : '335'}
            />
          </InfoBlock>
        </MiddleContainer>
      </TopBlock>
      <BottomContainer>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '90%',
            textTransform: 'none'
          }}
          onClick={() =>
            navigate('/buySubscription', {
              state: {
                isFreeTrial: true,
                deviceId
              }
            })
          }
        >
          Buy
        </Button>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          style={{
            width: '90%',
            textTransform: 'none'
          }}
          onClick={() =>
            navigate('/skip-trial', {
              state: {
                isFreeTrial: true,
                deviceId
              }
            })
          }
        >
          Continue Without Subscription
        </Button>
      </BottomContainer>
    </Wrapper>
  );
}
export default SubscriptionFunctions;
