import styled from 'styled-components';
import { Button, styled as styledMUI } from '@mui/material';
import { Flex } from 'styles';
import BreathingDetectionThumbnailIV from 'assets/promotionImages/BreathingMonitorThumbanilIV.png';
import SleepTrackingThumbnailIV from 'assets/promotionImages/SleepTrackingThumbnailIV.png';
import ActivityAlertsThumbnailIV from 'assets/promotionImages/ActivityAlertsThumbnailIV.png';
import NoWearablesThumbnailIV from 'assets/promotionImages/NoWearablesThumbnailIV.png';
import BreathingDetectionThumbnailLuna from 'assets/promotionImages/BreathingMonitoringThumbnailLuna.png';
import SleepTrackingThumbnailLuna from 'assets/promotionImages/SleepTrackingThumbnailLuna.png';
import ActivityAlertsThumbnailLuna from 'assets/promotionImages/ActivityAlertsThumbnailLuna.png';
import NoWearablesThumbnailLuna from 'assets/promotionImages/NoWearablesThumbnailLuna.png';
import { useLocation, useNavigate } from 'react-router-dom';
import FeatureList from './FeatureList';

export const Container = styledMUI('div')(({ theme }) => ({
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px'
  }
}));

export const Title = styled.div`
  margin-bottom: 24px;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.26px;
  width: 80%;
`;

const FeatureTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const whySubscribrFeaturesList = [
  {
    title: 'Breathing Monitoring',
    subTitle: "Ensure your baby's safety with real-time breathing monitoring.",
    thumbnailIV: BreathingDetectionThumbnailIV,
    thumbnailLuna: BreathingDetectionThumbnailLuna
  },
  {
    title: 'Sleep Tracking',
    subTitle: "Understand your baby's sleep patterns for better routines.",
    thumbnailIV: SleepTrackingThumbnailIV,
    thumbnailLuna: SleepTrackingThumbnailLuna
  },
  {
    title: 'Activity Alerts',
    subTitle: `Never miss a moment of your baby's precious activities.`,
    thumbnailIV: ActivityAlertsThumbnailIV,
    thumbnailLuna: ActivityAlertsThumbnailLuna
  },
  {
    title: 'No wearables',
    subTitle: `No electronic sensors or breathing wearables required to monitor your baby.`,
    thumbnailIV: NoWearablesThumbnailIV,
    thumbnailLuna: NoWearablesThumbnailLuna
  }
];

export default function WhySubscribe() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isFreeTrial, isUpgrade, deviceId, pairingFlow } =
    location.state || {};

  return (
    <Container>
      <Flex direction="column" align="start" style={{ width: '100%' }}>
        <Title>Try Sleep Insights for Free and Enjoy Restful Nights</Title>
      </Flex>
      <FeatureTitle>Feature Highlights:</FeatureTitle>
      <FeatureList featuresList={whySubscribrFeaturesList} showBenefits />
      <Flex
        direction="column"
        style={{
          width: '100%',
          padding: '12px 20px',
          gap: '10px',
          background: '#fff'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            outline: 'none',
            textTransform: 'none',
            '&:focus': {
              outline: 'none'
            }
          }}
          style={{
            color: '#fff',
            height: '52px',
            width: '100%',
            maxWidth: '300px',
            borderRadius: '44px',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px'
          }}
          onClick={() => {
            if (pairingFlow && deviceId) {
              navigate('/buySubscription', {
                state: {
                  isFreeTrial,
                  isUpgrade,
                  deviceId,
                  pairingFlow
                }
              });
            } else {
              navigate('/planlist', {
                state: {
                  isFreeTrial,
                  isUpgrade,
                  deviceId,
                  pairingFlow
                }
              });
            }
          }}
        >
          Start Free Trial
        </Button>
        <Button
          sx={{
            textTransform: 'none'
          }}
          style={{
            height: '52px',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px'
          }}
          onClick={() => {
            navigate('/missed-features', {
              state: {
                isFreeTrial,
                isUpgrade,
                deviceId,
                pairingFlow
              }
            });
          }}
        >
          Skip Free Trial
        </Button>
      </Flex>
    </Container>
  );
}
