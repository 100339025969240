import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { nextUpgradePlan } from 'utils/commonFunctions';
import CameraIcon from 'assets/camera_hd.png';
import { Flex, Image } from 'styles';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Button } from '@mui/material';
import { IsMobile } from 'hooks/isMobile';
import { IDevice, SubscriptionType } from 'services/api/subscriptionApi';

const HeadingIcon = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;
const CardHeading = styled.div`
  color: #494141;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;
const SpaceNameText = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  margin: 15px 0;
`;
const SubText = styled.div`
  color: #8c8a94;
`;
const AddCameraRow = styled.div`
  margin: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
`;

const InnerContainer = styled.div`
  display: flex;
  font-weight: 700;
  align-items: center;
  gap: 5px;
`;
const ImageContainer = styled.div`
  border-radius: 8px;
  background: #f1f1f4;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RightContainer = styled.div`
  margin: 10px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;
const PlanChip = styled.div`
  font-size: 12px;
  border-radius: 6px;
  background: #d7eea1;
  padding: 4px 8px;
`;
const ErrorBanner = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
`;

const NuddgeBanner = styled.div`
  width: 300px;
  border-radius: 12px;
  background: #92c1fc;
  padding: 12px;
`;

const NudgeText = styled.div`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin-bottom: 16px;
`;

const CameraCountInfo = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

type IProps = {
  selectedSubscription: SubscriptionType | null;
  openModal: (value: string) => void;
};
export type IDeviceRes = {
  id: string;
  space_id: string;
  name: string;
  location: string;
  manufacturer: {
    name: string;
    model: string;
    year: number;
  };
  device_state: {
    battery_percentage: number;
    wifi_name: string;
    status: string;
    firmware_version: string;
    ip_addr: string;
    last_seen: string;
  };
  activation_date: string;
  pairing_status: string;
};

function AddOrRemoveCamera(props: IProps) {
  const { selectedSubscription, openModal } = props;
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [subscriptionDevices, setSubscriptionDevices] = useState<IDevice[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fetchDevices = async () => {
    axios
      .get(`parents/spaces/${selectedSubscription?.space_id}/devices`)
      .then((res) => {
        let _devices: IDevice[] = [];
        _devices = res.data.map((item: IDeviceRes) => {
          return {
            id: item.id,
            name: item.name
          };
        });
        const _selectedDeviceIds: string[] =
          selectedSubscription?.devices.map((device: IDevice) => device.id) ||
          [];
        _devices = _devices.filter(
          (device) => !_selectedDeviceIds.includes(device.id)
        );
        setDevices(_devices);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addDevice = async (dId: string) => {
    if (
      subscriptionDevices.length ===
      selectedSubscription?.supported_camera_count
    ) {
      openModal('MODIFY');
      return;
    }
    const payload = {
      device_id: dId
    };
    try {
      const res = await axios.post(
        `parents/subscriptions/${selectedSubscription?.id}/devices`,
        payload
      );
      console.log(res);
      const _device = devices.find((item) => item.id === dId)!;
      setDevices((values) => values.filter((item) => item.id !== dId));
      setSubscriptionDevices([...subscriptionDevices, _device]);
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError?.response && axiosError.response.status === 400) {
        const upgradePlanName = nextUpgradePlan(selectedSubscription?.sku_id);
        setErrorMessage(
          `Please upgrade your subscription to ${upgradePlanName}.`
        );
      } else {
        console.log(axiosError);
      }
    }
  };

  const removeDevice = async (dId: string) => {
    try {
      const res = await axios.delete(
        `parents/subscriptions/${selectedSubscription?.purchase_id}/devices/${dId}`
      );
      console.log(res);
      const _device = subscriptionDevices.find((item) => item.id === dId)!;
      setSubscriptionDevices((values) =>
        values.filter((item) => item.id !== dId)
      );
      setDevices([...devices, _device]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const _devices: IDevice[] = [];
    selectedSubscription?.devices.map((item: IDevice) => _devices.push(item));
    setSubscriptionDevices(_devices);
    fetchDevices();
  }, []);

  return (
    <>
      <Flex wrap="wrap">
        <HeadingIcon />
        <CardHeading>{selectedSubscription?.sku_name} Plan</CardHeading>
      </Flex>
      <CameraCountInfo>
        {subscriptionDevices.length}/
        {selectedSubscription?.supported_camera_count} cameras included in{' '}
        {selectedSubscription?.sku_name} Plan
      </CameraCountInfo>
      {errorMessage !== '' && <ErrorBanner>{errorMessage}</ErrorBanner>}
      <Flex
        justify="space-evenly"
        direction={IsMobile() ? 'column' : 'row'}
        gap="10px"
      >
        <NuddgeBanner>
          <Flex>
            <Flex direction="column" align="baseline">
              <NudgeText>Add a camera to your plan for $3.99</NudgeText>
              <Button
                variant="outlined"
                sx={{
                  width: '113px',
                  height: '48px',
                  color: '#fff'
                }}
              >
                Learn More
              </Button>
            </Flex>
            <Image src={CameraIcon} width="73" height="94" />
          </Flex>
        </NuddgeBanner>
        <NuddgeBanner>
          <Flex>
            <Flex direction="column" align="baseline">
              <NudgeText>Add a camera to your plan for $3.99</NudgeText>
              <Button
                variant="outlined"
                sx={{
                  width: '113px',
                  height: '48px',
                  color: '#fff'
                }}
              >
                Learn More
              </Button>
            </Flex>
            <Image src={CameraIcon} width="73" height="94" />
          </Flex>
        </NuddgeBanner>
      </Flex>

      <SpaceNameText>{selectedSubscription?.space_name} Plan</SpaceNameText>
      <SubText>Cameras</SubText>
      {devices.map((item) => (
        <AddCameraRow key={item.id}>
          <InnerContainer>
            <ImageContainer>
              <Image src={CameraIcon} width="22" height="28" />
            </ImageContainer>
            {item.name}
          </InnerContainer>
          <RightContainer>
            <IconButton
              aria-label="add"
              size="medium"
              onClick={() => addDevice(item.id)}
            >
              <AddCircleOutlineRoundedIcon
                fontSize="medium"
                sx={{ padding: 0 }}
                style={{ color: 'grey' }}
              />
            </IconButton>
          </RightContainer>
        </AddCameraRow>
      ))}
      {subscriptionDevices.map((item) => (
        <AddCameraRow key={item.id}>
          <InnerContainer>
            <ImageContainer>
              <Image src={CameraIcon} width="22" height="28" />
            </ImageContainer>
            {item.name}
          </InnerContainer>
          <RightContainer>
            <PlanChip>{selectedSubscription?.sku_name}</PlanChip>
            <IconButton
              aria-label="add"
              sx={{ padding: 0 }}
              size="medium"
              onClick={() => removeDevice(item.id)}
            >
              <DeleteOutlineOutlinedIcon
                fontSize="medium"
                style={{ color: 'grey' }}
              />
            </IconButton>
          </RightContainer>
        </AddCameraRow>
      ))}
    </>
  );
}
export default AddOrRemoveCamera;
