/* eslint-disable import/no-extraneous-dependencies */
import { Elements } from '@stripe/react-stripe-js';
import { stripeOptions, stripePromise } from 'configs/stripeConfig';
import { useEffect, useState } from 'react';
import axios from 'axios';
import StripeForm from './StripeForm';

type IProps = {
  submitPurchaseRequest: (stripe_payment_method: string) => void;
  amount: number;
  isFreeTrial: boolean;
  isUpgrade: boolean;
};

function PaymentScreenDemo(props: IProps) {
  const { submitPurchaseRequest, amount, isUpgrade, isFreeTrial } = props;
  const [options, setOptions] = useState(stripeOptions);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .post('piggybank/payment_gateway/setup_intents')
      .then((res) => {
        const { client_secret } = res.data;
        setOptions({ ...stripeOptions, clientSecret: client_secret });
        setLoading(false);
      })
      .catch((err) => {
        console.log('stripesetup err', err);
        setLoading(false);
      });
  }, []);

  if (loading) return <h1>Loading...</h1>;
  return (
    <div className="payment-screen" style={{ padding: '0 10px' }}>
      <Elements stripe={stripePromise} options={options}>
        <StripeForm
          amount={amount}
          isUpgrade={isUpgrade}
          isFreeTrial={isFreeTrial}
          submitPurchaseRequest={submitPurchaseRequest}
        />
      </Elements>
    </div>
  );
}

export default PaymentScreenDemo;
