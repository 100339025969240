/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  Typography,
  IconButton,
  Menu,
  Avatar,
  MenuItem,
  Tooltip
} from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { LogoutUser } from 'store/store';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import { IsMobile } from 'hooks/isMobile';
import CloseIcon from '@mui/icons-material/Close';

type IProps = {
  setOpenSideBar: (state: boolean) => void;
  openSideBar: boolean;
};
function TopBar(props: IProps) {
  const { setOpenSideBar, openSideBar } = props;
  const isMobile = IsMobile();
  const { userDetails } = useAppSelector((state) => state.user);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogOut = async () => {
    try {
      LogoutUser();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TopBarContainer isMobile={isMobile}>
      {isMobile && (
        <HamburgerContainer>
          {!openSideBar ? (
            <MenuIcon
              onClick={() => setOpenSideBar(true)}
              sx={{ width: 30, height: 30, color: 'white' }}
            />
          ) : (
            <CloseIcon
              onClick={() => setOpenSideBar(false)}
              sx={{ width: 30, height: 30, color: 'white' }}
            />
          )}
        </HamburgerContainer>
      )}
      {!isMobile && <TopBarHeading>User Portal</TopBarHeading>}
      <Tooltip title={`${userDetails?.first_name} ${userDetails?.last_name}`}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar>{userDetails?.first_name[0]}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={onLogOut}>
          <Typography textAlign="center">Log out</Typography>
        </MenuItem>
      </Menu>
    </TopBarContainer>
  );
}

export default TopBar;

const TopBarContainer = styled.div<{ isMobile?: boolean }>`
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.isMobile ? 'black' : 'white')};
  position: fixed;
  top: 0;
  width: ${IsMobile() ? '100%' : 'calc(100% - 340px)'};
  z-index: 1299;
`;

const TopBarHeading = styled.div`
  font-size: 30px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.68px;
`;

const HamburgerContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
