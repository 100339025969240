import styled from 'styled-components';
import { ReactComponent as CheckIconIV } from 'assets/check-circle-iv.svg';
import { ReactComponent as CloseIconIV } from 'assets/close-circle-iv.svg';
import { ReactComponent as CheckIconLuna } from 'assets/check-circle-luna.svg';
import { ReactComponent as CloseIconLuna } from 'assets/close-circle-luna.svg';
import { getPartnerId } from 'utils/commonFunctions';
import { PARTNER } from 'partner_configuration/config';

const CardRoot = styled.div`
  padding: 12px;
  border-radius: 16px;
  gap: 12px;
  display: flex;
  background: #f0f4f4;
  flex-direction: column;
  margin-bottom: 8px;
`;

const CardHead = styled.div`
  display: flex;
  gap: 12px;
`;

const CardBody = styled.div`
  display: flex;
  justify-content: center;
`;

const CardTitle = styled.div<{ partnerName: string }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${({ partnerName }) =>
    partnerName === PARTNER.LUNA ? '#2C3E50' : '#000000'};
  margin-bottom: 4px;
`;

const CardSubTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #484c4f;
`;

const CardIcon = styled.div`
  display: flex;
  align-items: center;
`;

interface Feature {
  title: string;
  subTitle: string;
  thumbnailIV: string;
  thumbnailLuna: string;
}

interface FeatureListProps {
  featuresList: Feature[];
  showBenefits: boolean; // Indicates whether to show benefit view or missed view
}

const getIcon = (showBenefits: boolean, partnerName: string) => {
  if (showBenefits) {
    return partnerName === PARTNER.LUNA ? <CheckIconLuna /> : <CheckIconIV />;
  }
  return partnerName === PARTNER.LUNA ? <CloseIconLuna /> : <CloseIconIV />;
};

export default function FeatureList({
  featuresList,
  showBenefits
}: FeatureListProps) {
  const partnerName = getPartnerId();
  return (
    <>
      {featuresList.map((item) => {
        const thumbnail =
          partnerName === PARTNER.LUNA ? item.thumbnailLuna : item.thumbnailIV;

        return (
          <CardRoot key={`card-root-${item.title}`}>
            <CardHead>
              <CardIcon>{getIcon(showBenefits, partnerName)}</CardIcon>
              <div>
                <CardTitle partnerName={partnerName}>{item.title}</CardTitle>
                <CardSubTitle>{item.subTitle}</CardSubTitle>
              </div>
            </CardHead>
            <CardBody>
              <img src={thumbnail} style={{ width: '100%' }} alt={item.title} />
            </CardBody>
          </CardRoot>
        );
      })}
    </>
  );
}
