/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GetSubscriptionDetails,
  SubscriptionType,
  SubScriptionState
} from 'services/api/subscriptionApi';

export const fetchAllSubscription = createAsyncThunk(
  'spaces/fetchAllSubscription',
  async (payload, thunkAPI) => {
    try {
      const response = await GetSubscriptionDetails();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue('failed');
    }
  }
);

export const clearSubscriptionData = createAsyncThunk(
  'spaces/clearSubscriptionData',
  async (payload, thunkAPI) => {
    try {
      const response = await (() => {});
      return response;
    } catch {
      return thunkAPI.rejectWithValue('failed');
    }
  }
);

const initialSubscriptionState: SubScriptionState = {
  subscriptionData: [],
  isLoading: false
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: initialSubscriptionState,
  reducers: {
    // clearSubscriptionData: (state) => {
    //   state.subscriptionData = [];
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscriptionData = action.payload
          .subscriptions as SubscriptionType[];
      })
      .addCase(fetchAllSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllSubscription.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(clearSubscriptionData.fulfilled, (state) => {
        state.isLoading = false;
        state.subscriptionData = [];
      })
      .addCase(clearSubscriptionData.pending, (state) => {
        state.isLoading = false;
        state.subscriptionData = [];
      })
      .addCase(clearSubscriptionData.rejected, (state) => {
        state.isLoading = false;
        state.subscriptionData = [];
      });
  }
});

// Action creators are generated for each case reducer function
// export const { clearSubscriptionData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
