/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetUserDetails } from 'services/api/UserApi';
import { UserResponseType, UserState } from 'typings/userTypes';
import { removeRequestHeaders } from 'utils/commonFunctions';

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (payload, thunkAPI) => {
    try {
      const response = await GetUserDetails();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue('failed');
    }
  }
);

const initialUserState: UserState = {
  userDetails: null,
  isLoading: false,
  isAuthenticated: false,
  isEligibleForTrial: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    clearUser: (state) => {
      state.userDetails = null;
      state.isAuthenticated = false;
      state.isEligibleForTrial = false;
      removeRequestHeaders();
    },
    updateFreeTrialEligibility: (state, action) => {
      state.isEligibleForTrial = action.payload || false;
    },
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload as UserResponseType;
      state.isAuthenticated = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.user_account as UserResponseType;
        state.isAuthenticated = true;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
      });
  }
});

// Action creators are generated for each case reducer function
export const { clearUser, updateFreeTrialEligibility, updateUserDetails } =
  userSlice.actions;

export default userSlice.reducer;
