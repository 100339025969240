/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unneeded-ternary */
import { styled as styledMUI } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { SubscriptionPlanContent } from 'components/SubscriptionPlanContent/SubscriptionPlanContent';

const Gutter = styledMUI('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px'
  }
}));

function PlanList() {
  //   debugger;
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const deviceFromQuery = query.get('deviceId') || '';
  const pairingFlowFromQuery =
    query.get('pairingFlow') === 'true' ? true : false;

  //   debugger;
  return (
    <Gutter>
      <SubscriptionPlanContent
        deviceId={deviceFromQuery}
        pairingFlow={pairingFlowFromQuery}
      />
    </Gutter>
  );
}
export default PlanList;
