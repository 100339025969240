import { FREE_TRIAL_DURATION } from 'constants/constants';
import { Flex } from 'styles';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import WearablesThumbnail from 'assets/promotionImages/WearablesThumbnail.png';
import NoActivityAlertThumbnail from 'assets/promotionImages/NoActivityAlertThumbnail.png';
import NoBreathingMonitorThumbnail from 'assets/promotionImages/NoBreathingMonitorThumbnail.png';
import NoSleepTrackingThumbnail from 'assets/promotionImages/NoSleepTrackingThumbnail.png';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Title } from './WhySubscribe';
import FeatureList from './FeatureList';

const FeaturesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: ${(props) => props.theme.palette.background.notice};
  border: 1px solid ${(props) => props.theme.palette.background.secondary};
  font-color: ${(props) => props.theme.palette.text.secondary};
  border-radius: 20px;
  padding: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
`;

const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: start;
`;

const FeatureBullet = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  display: flex;
  width: 8px;
  height: 8px;
  margin: auto 0;
`;

const FeatureText = styled.div`
  margin: auto 0;
`;

export const missedFeaturesList = [
  {
    title: 'No Breathing Monitoring',
    subTitle:
      'Keeping your baby safe means doing manual checks, which may disrupt their sleep.',
    thumbnailIV: NoBreathingMonitorThumbnail,
    thumbnailLuna: NoBreathingMonitorThumbnail
  },
  {
    title: 'No Sleep Tracking',
    subTitle:
      'Unable to notice patterns and schedule naps at predictable times, for both you and your baby.',
    thumbnailIV: NoSleepTrackingThumbnail,
    thumbnailLuna: NoSleepTrackingThumbnail
  },
  {
    title: 'No Activity Alerts',
    subTitle: `It's easy to miss important moments, like when your baby wakes or cries for comfort.`,
    thumbnailIV: NoActivityAlertThumbnail,
    thumbnailLuna: NoActivityAlertThumbnail
  },
  {
    title: 'Monitoring with Wearables',
    subTitle: `Other brands with wearable monitor may cause discomfort or skin irritation, disrupting your baby's natural movements.`,
    thumbnailIV: WearablesThumbnail,
    thumbnailLuna: WearablesThumbnail
  }
];

export default function MissedFeatures() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  // Extract values from location.state
  const { isFreeTrial, isUpgrade, deviceId, pairingFlow } =
    location.state || {};

  return (
    <Container>
      <Flex direction="column" align="start" style={{ width: '100%' }}>
        <Title>Proceed With Basic Camera Only?</Title>
        <FeaturesWrapper theme={theme}>
          <FeatureContainer>
            <FeatureBullet theme={theme} />
            <FeatureText>
              {FREE_TRIAL_DURATION} day free trial without any charges
            </FeatureText>
          </FeatureContainer>
          <FeatureContainer>
            <FeatureBullet theme={theme} />
            <FeatureText>Cancel anytime during the trial period</FeatureText>
          </FeatureContainer>
          <FeatureContainer>
            <FeatureBullet theme={theme} />
            <FeatureText>We notify a day before trial ends</FeatureText>
          </FeatureContainer>
        </FeaturesWrapper>
        <FeatureList featuresList={missedFeaturesList} showBenefits={false} />
        <Flex
          direction="column"
          style={{
            width: '100%',
            padding: '12px 20px',
            gap: '10px',
            background: '#fff'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              outline: 'none',
              textTransform: 'none',
              '&:focus': {
                outline: 'none'
              }
            }}
            style={{
              color: '#fff',
              height: '52px',
              width: '100%',
              maxWidth: '300px',
              borderRadius: '44px',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px'
            }}
            onClick={() => {
              if (pairingFlow && deviceId) {
                navigate('/buySubscription', {
                  state: {
                    isFreeTrial,
                    isUpgrade,
                    deviceId,
                    pairingFlow
                  }
                });
              } else {
                navigate('/planlist', {
                  state: {
                    isFreeTrial,
                    isUpgrade,
                    deviceId,
                    pairingFlow
                  }
                });
              }
            }}
          >
            Start Free Trial
          </Button>
          <Button
            sx={{
              textTransform: 'none'
            }}
            style={{
              height: '52px',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px'
            }}
            onClick={() => {
              navigate('/skip-trial', {
                state: {
                  isFreeTrial,
                  deviceId,
                  pairingFlow
                }
              });
            }}
          >
            Continue with Sleepless Nights
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
}
