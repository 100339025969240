type ICURRENCY = {
  [key: string]: string;
};
export const CURRENCY: ICURRENCY = {
  USD: '$',
  EUR: '€',
  GBP: '£'
};

export const CUSTOMER_SERVICE = {
  email: 'support@instababy.ai',
  phone: '1-234-567-8910'
};

export const SUBSCRIBE_STATUS = {
  Active: 'Active',
  Cancelled: 'Cancelled'
};

export const FREE_TRIAL_DURATION: number = 7;
