import axios from 'axios';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export interface IPaymentMethods {
  default_payment_method: string;
  payment_method: PaymentMethod[];
}

export interface PaymentMethod {
  id: string;
  type: string;
  address: Address;
  card: Card;
}

export interface Address {
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
}

export interface Card {
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  funding: string;
}

export interface ISetupIntent {
  client_secret: string;
}

export async function GetPaymentMethods(): Promise<
  ApiResponse<IPaymentMethods>
> {
  const response = await axios.get(endpoints.paymentMethodsUrl);
  return response;
}

export async function PaymentSetupIntent(): Promise<ApiResponse<ISetupIntent>> {
  const response = await axios.post(endpoints.setupIntentUrl);
  return response;
}
