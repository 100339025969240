import { Button, Radio } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useContext, useEffect, useState } from 'react';
import { endpoints } from 'services/api/endpoints';
import axios from 'axios';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import { ConfigContext } from 'context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;
const TopBanner = styled.div`
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  margin-bottom: 10px;
`;
const SubHeading = styled.div`
  color: #8c8a94;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: 80%;
  margin-bottom: 20px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: flex-end;
  background-color: #ffffff;
  padding: 20px 0;
`;

const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
`;

interface skipList {
  id: string;
  reason: string;
}

interface skipReason {
  reason_id: number;
  other?: string;
}
function SkippingTrial() {
  const navigate = useNavigate();
  const [skipList, setSkipList] = useState<skipList[]>([]);
  const [skipReason, setSkipReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const location = useLocation();
  const config = useContext(ConfigContext);
  const { deviceId, pairingFlow } = location.state || {};

  const authenticatDevice = async () => {
    await axios.patch(endpoints.updateDevices(deviceId), {
      is_activated: true
    });
  };
  const submitReason = async () => {
    const payload: skipReason = {
      reason_id: Number(skipReason)
    };
    if (Number(skipReason) === 6) {
      payload['other'] = otherReason;
    }
    if (deviceId && pairingFlow) {
      await authenticatDevice();
    }

    axios
      .post(endpoints.userSkipTrial, payload)
      .then((res) => {
        console.log('skip free trial', res.data);
      })
      .catch((err) => {
        console.log('skip trial error', err);
      })
      .finally(() => {
        if (isMobile && (isIOS || isAndroid)) {
          const link = document.createElement('a');
          link.setAttribute('href', config.universalLink || '');
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
        } else {
          navigate('/ActiveDevices');
        }
      });
  };

  useEffect(() => {
    axios
      .get(endpoints.skipFreeTrial)
      .then((res) => {
        console.log('skip free trial', res.data);
        setSkipList(res.data);
      })
      .catch((err) => {
        console.log('skip trial error', err);
      });
  }, []);

  const handleChange = (e: { target: { value: string } }) => {
    setSkipReason(e.target.value);
    setOtherReason('');
  };
  return (
    <Wrapper>
      <TopBlock>
        <TopBanner>Why are you skipping your FREE Trail?</TopBanner>
        <SubHeading>Help us create a better experience</SubHeading>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
        >
          {skipList.map((el) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <FormControlLabel
                control={<Radio />}
                label={el.reason}
                id={el.id}
                key={el.id}
                value={el.id}
              />
            );
          })}
        </RadioGroup>
        {Number(skipReason) === 6 && (
          <TextArea
            value={otherReason}
            onChange={(e: { target: { value: string } }) =>
              setOtherReason(e.target.value)
            }
          />
        )}
      </TopBlock>
      <BottomContainer>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: '50%',
            borderRadius: '25px',
            marginRight: '10px',
            outline: 'none',
            '&:focus': {
              outline: 'none'
            }
          }}
          onClick={() => {
            submitReason();
          }}
          disabled={!skipReason}
        >
          Continue
        </Button>
      </BottomContainer>
    </Wrapper>
  );
}
export default SkippingTrial;
